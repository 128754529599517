.mainContainer_footer{
    background-color: rgb(23, 23, 23);
margin-top: auto;
color: rgb(220, 220, 220);
padding: 5px 20px; 
}

.subcontainer_footer{
    display: flex;
    justify-content: space-between;
    padding: 5px 50px;
}


.rightside_footer p{
    width: 15vw;
    font-size: small;
}

.rightside_footer li{
    list-style: none;
    font-size: small;
}

.leftSidefooter li{
    list-style: none;
}

.leftSidefooter li p{
    width: 25vw;
    font-size: small;
}

.laststatement_footer{
    text-align: center;
    border-top: 1px solid grey;
    font-size: small;
}

@media only screen and (max-width: 600px){
    .subcontainer_footer{
        flex-direction: column;
        padding: 0;
    }

    .rightside_footer{
        margin-top: 20px;
    }
    .rightside_footer p{
        width: 100%;
        font-size: small;
    }

    .leftSidefooter li p{
        width: 100%;
    }
}