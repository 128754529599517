.mainContainerAboutUs{
    padding: 30px 50px;
    display: flex;
    justify-content: space-between;
    
    width: 100%;

}

.subContainer_LeftSideAbouUS{
    width: 70%;
}

.mainContainerAboutUs h5{
    font-size: large;
}

.mainContainerAboutUs p{
    font-size: small;
}

.mainContainerAboutUs li{
    font-size: small;
}

.image1_rightSIde_aboutus{
    width:24vw;
    position: relative;
    left: 18vw;
    overflow: hidden;

}

.strongtext{
    color: rgb(0, 137, 228);
}




@media only screen and (max-width: 600px){
    .mainContainerAboutUs{
        margin-top: 10vh;
        flex-direction: column;
    }
    .subContainer_LeftSideAbouUS{
        width: 100%;
    }
    .mainContainerAboutUs h5{
        font-size: x-large;
    }
    .mainContainerAboutUs p{
        font-size: medium;
    }

    .mainContainerAboutUs li{
        font-size: medium;
    }

    .image1_rightSIde_aboutus{
        display: none;
    }
}