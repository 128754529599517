.mapCard_card{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    padding: 50px 50px;
    row-gap: 50px;
    
    
}
.card{
    padding: 5px;
    box-shadow: 2px 2px 10px 1px rgba(144, 144, 144, 0.463);
    border: none;
    width: 21vw;
}
.imageCard{
    padding: 2px;
    transition: 0.4s ease-in-out;
}

.imageCard:hover{
    transform: scale(1.3);
    cursor: pointer;
    overflow: scroll;
    z-index: 9999; 
}


@media only screen and (max-width: 600px){
    .mapCard_card{
        margin-top: 50px;
        grid-template-columns: repeat(2,1fr);
        padding: 50px 10px;
        row-gap: 20px;
    }

    .card{
        width: 96%;
        align-items: center;
        align-self: center;
    }
}