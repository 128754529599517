.mainContainerGallery{
    min-height: 100vh;
    display: flex;
    justify-content: space-evenly;
    padding: 50px 50px;
}



.subcontainer_video{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    
}

.video_gallery{
    width: 45vw;
    height:50vh;
    
    box-shadow: 5px 5px 5px 1px rgb(176, 176, 176);

}

.rightSideGallery{
    display: flex;
    flex-direction: column;
    text-align: center;
    row-gap: 50px;
}

.rightSideGallery h1{
    color: rgb(0, 137, 228);
}

.rightSideGallery h6{
    color: darkgray;
    position: relative;
    bottom: 55px;
}


.rightSideGallery h3{
    color: gray;
    font-variant: small-caps;
}

.rightside_call p{
    color: gray;
    
}

.mobile_rightsideGallery{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
}



@media only screen and (max-width: 600px){
    .mainContainerGallery{
        flex-direction: column;
        margin-top: 10vh;
        padding:10px;
        row-gap: 50px;
    }


    .subcontainer_video{
        row-gap: 30px;
    }

    .video_gallery{
        width: 100%;
        height: 25vh;
    }

    .rightSideGallery{
        row-gap: 0px;
    }



    .rightSideGallery h6{
        bottom: 10px;
    }
}