.MainConatiner{
    
    height: 60px;
}

.subCOntainer_Navbar{
    display: flex;
    padding: 10px 50px;
    justify-content: space-between;
}


.rightSide_navbar{
    display: flex;
    column-gap: 30px;
    font-size: medium;
}

.leftSide_Navbar{
    display: flex;
    flex-direction: column; 
}


.leftSide_Navbar h5{
    font-size: large;
    color: rgb(0, 137, 228);
}
.leftSide_Navbar p{
    font-size: small;
    position: relative;
    bottom: 10px;
    color: darkgray;
}
.link{
text-decoration: none;
}

.rightSide_navbar p{
    text-decoration: none;   
    color: black;
}

.rightSide_navbar p:hover{
    color: grey;
}



@media only screen and (max-width: 600px){
    .subCOntainer_Navbar{
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding: 20px;
    }

    .leftSide_Navbar h5{
        font-size: x-large;
    }
}